import loadable from "@loadable/component";

const Dashboard = loadable(() => import("../pages/dashboard"));
const Settings = loadable(() => import("../pages/settings"));
const Optimization = loadable(() =>
  import("../pages/optimizationRecommendations")
);

export const enterpriseRoutes = [
  {
    id: "dashboard",
    path: "/dashboard/*",
    component: <Dashboard />,
  },
  {
    id: "optimization",
    path: "/optimization/*",
    component: <Optimization />,
  },
  {
    id: "settings",
    path: "/settings",
    component: <Settings />,
  },
];
