import { createTheme } from "@mui/material";
import { LightThemeColors } from "../constants/colors/lightThemeColors";

export const theme = (data) => {
  const { primary, secondary, button, text, chip, background } =
    data?.colors ?? {};
  return createTheme({
    palette: {
      primary: primary || {
        light: LightThemeColors.primary.light,
        main: LightThemeColors.primary.main,
        dark: LightThemeColors.primary.dark,
      },
      secondary: secondary || {
        light: LightThemeColors.secondary.light,
        main: LightThemeColors.secondary.main,
        dark: LightThemeColors.secondary.dark,
      },
      background: background || {
        default: LightThemeColors.background.default,
        light: LightThemeColors.background.light,
      },
      button: button || {
        main: LightThemeColors.button.main,
      },
      text: text || {
        primary: LightThemeColors.text.primary,
        secondary: LightThemeColors.text.secondary,
      },
      chip: chip || {
        greenShade: LightThemeColors.chip.greenShade,
        redShade: LightThemeColors.chip.redShade,
      },
    },
    typography: {
      fontFamily: "Aeonik, Roboto, sans-serif",
    },

    components: {
      MuiChip: {
        styleOverrides: {
          label: {
            fontFamily: "Aeonik, Roboto, sans-serif",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: [
              "0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
              "0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
            ],

            borderRadius: "12px",
            border: "1px solid #EAECF0",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "2px 10px 2px 10px",
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            fontWeight: 500,
          },
        },
      },
      MuiPickersYear: {
        styleOverrides: {
          root: {
            fontWeight: 500,
          },
        },
      },
    },
  });
};
