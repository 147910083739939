//Create a Http Client using Axios. Further modifications in this layer can be done later like Authorization.
import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL } from "../config";
import { store } from "../store/store";
import { setRefreshToggler } from "../store/appReducer/appReducer";
import { ApiService } from "./api.services";

let isAlreadyFetchingAccessToken = false;
let subscribers = [];
let sifioInterceptor;

const createJsonInstanceWithoutToken = () => {
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

const createJsonInstanceWithToken = () => {
  const token = Cookies.get("accessToken");
  const tenantId = Cookies.get("tenantId");
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      tenantId: tenantId,
    },
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const {
        config,
        response: { status },
      } = error;
      makeCallForNewRefreshToken(config, status);
      return Promise.reject(error);
    }
  );

  return instance;
};

const createXmlInstanceWithTempToken = () => {
  const tokenTemp = Cookies.get("tempAccessToken");
  const tenantId = Cookies.get("tenantId");
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${tokenTemp}`,
      tenantId: tenantId,
    },
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

const createXmlInstance = () => {
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

// Function to make the API call using Axios
const makeApiCall = async (apiInstance, endpoint, data) => {
  try {
    return await apiInstance.post(endpoint, data);
  } catch (error) {
    return error;
  }
};
const makeGetApiCall = async (apiInstance, endpoint) => {
  try {
    return await apiInstance.get(endpoint);
  } catch (error) {
    return error;
  }
};

const removeAuthorization = () =>
  axios.interceptors.request.eject(sifioInterceptor);

const makeCallForNewRefreshToken = (config, status) => {
  const originalRequest = config;
  if (status === 401) {
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const resp = makeApiCall(
        createXmlInstance(),
        `${BASE_URL}Authentication/Auth`,
        createRefreshTokenFormData()
      );

      resp
        .then((responseValue) => {
          if (responseValue.status === 200 && responseValue?.data?.isSuccess) {
            Cookies.set("accessToken", responseValue?.data?.data?.accessToken);
            Cookies.set(
              "refreshToken",
              responseValue?.data?.data?.refreshToken
            );
            const userData = ApiService.decodeData(
              responseValue?.data?.data?.accessToken
            );
            const tenants = JSON.parse(userData?.tenants);
            const object = tenants.find(
              (obj) => obj.Id == Cookies.get("tenantId")
            );
            if (!object) {
              clearCache();
              return;
            }
            store.dispatch(setRefreshToggler());
          } else if (responseValue.response.status === 401) {
            clearCache();
          }
          isAlreadyFetchingAccessToken = false;
        })
        .catch(() => {
          clearCache();
        });
    } else if (originalRequest?.url === `${BASE_URL}Authentication/Auth`) {
      clearCache();
    }
  }
};

export const clearCache = () => {
  Cookies.remove("accessToken");
  Cookies.remove("refreshToken");
  Cookies.remove("tenantId");
  window.location.reload();
};

// Create Refresh token Form Data
export const createRefreshTokenFormData = () => ({
  grant_type: "refresh_token",
  refresh_token: Cookies.get("refreshToken"),
  scope: "openid offline_access hospitalscope",
  client_id: "reactSPA",
  client_secret: "sifio_K9hJ8fT4",
});

const onAccessTokenFetched = (access_token) => {
  subscribers = subscribers.filter((callback) => callback(access_token));
};

const addSubscriber = (callback) => {
  subscribers.push(callback);
};

const postWithJsonToken = (url = "", data = {}) => {
  return makeApiCall(createJsonInstanceWithToken(), url, data);
};
const postWithJsonNoToken = (url = "", data = {}) => {
  return makeApiCall(createJsonInstanceWithoutToken(), url, data);
};
const postWithXmlToken = (url = "", data = {}) => {
  return makeApiCall(createXmlInstanceWithTempToken(), url, data);
};
const postWithXmlNoToken = (url = "", data = {}) => {
  return makeApiCall(createXmlInstance(), url, data);
};

const getWithJsonToken = (url = "") => {
  return makeGetApiCall(createJsonInstanceWithToken(), url);
};
const get = (url = "", config = {}) => {
  return axios(url, config);
};

const put = (url = "", data = {}, config = {}) => {
  return axios.put(url, data, config);
};

const del = (url = "", data = {}, config = {}) => {
  return axios.delete(url, { headers: config, data: data });
};

const HttpClient = {
  postWithXmlToken,
  postWithXmlNoToken,
  postWithJsonToken,
  postWithJsonNoToken,
  getWithJsonToken,
  get,
  put,
  del,
  removeAuthorization,
};

export { HttpClient };
