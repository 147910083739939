const OptimizationIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 13.71V15.71M10 9.70996V15.71M14 5.70996V15.71M5.8 19.71H14.2C15.8802 19.71 16.7202 19.71 17.362 19.383C17.9265 19.0954 18.3854 18.6364 18.673 18.0719C19 17.4302 19 16.5901 19 14.91V6.50996C19 4.8298 19 3.98972 18.673 3.34799C18.3854 2.7835 17.9265 2.32456 17.362 2.03694C16.7202 1.70996 15.8802 1.70996 14.2 1.70996H5.8C4.11984 1.70996 3.27976 1.70996 2.63803 2.03694C2.07354 2.32456 1.6146 2.7835 1.32698 3.34799C1 3.98972 1 4.8298 1 6.50996V14.91C1 16.5901 1 17.4302 1.32698 18.0719C1.6146 18.6364 2.07354 19.0954 2.63803 19.383C3.27976 19.71 4.11984 19.71 5.8 19.71Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OptimizationIcon;
