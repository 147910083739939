import {
  Box,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { profileMenus } from "./profileMenus";
import ListItemIcon from "@mui/material/ListItemIcon";
import { profileStyles } from "./useStyles";
import { useAppDispatch } from "../../../../store/hook";
import { callLogout } from "../../../../pages/signIn/signInServices";
import LogoutModal from "../../modal/LogoutModal";
import Cookies from "js-cookie";

const ProfileSection = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (e) => {
    e?.stopPropagation();
    setOpen(false);
  };
  const handleLogout = (val) => {
    if (val?.id === "logout") {
      dispatch(callLogout());
    }
    handleClose();
  };

  const UserDetailes = Cookies.get("userData")
    ? JSON.parse(Cookies.get("userData"))
    : {};

  return (
    <Grid container>
      <Grid item sx={{ padding: 1 }}>
        <Box sx={profileStyles.profileBox}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Grid item sx={profileStyles.profileGridLogoMain}>
              {UserDetailes?.firstName?.substring(0, 1)}
              {UserDetailes?.lastName?.substring(0, 1)}
            </Grid>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: 135,
              horizontal: "left",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            sx={profileStyles.profileMenu}
          >
            <Grid
              container
              flexWrap="nowrap"
              sx={profileStyles.profileGrid}
              alignItems="center"
            >
              <Grid item sx={profileStyles.profileGridLogo}>
                {UserDetailes?.firstName?.substring(0, 1)}
                {UserDetailes?.lastName?.substring(0, 1)}
              </Grid>
              <Grid item sx={profileStyles.userGrid}>
                <Grid container flexDirection="column">
                  <Grid item>
                    <Typography sx={profileStyles.profileNameTypography}>
                      {UserDetailes?.fullName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={profileStyles.userNameTypography}>
                      {UserDetailes?.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            {profileMenus.map((menu) => (
              <MenuItem
                key={menu.id}
                onClick={() => {
                  handleCloseUserMenu();
                  handleClickOpen();
                }}
                sx={{ ...profileStyles.profileMenuItem }}
              >
                <ListItemIcon>{menu.icon}</ListItemIcon>
                <Grid>
                  <Typography sx={{ fontWeight: 500, color: "" }}>
                    Logout
                  </Typography>
                </Grid>
                <LogoutModal
                  open={open}
                  setOpen={setOpen}
                  handleClose={handleClose}
                  handleLogout={handleLogout}
                  menu={menu}
                />
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ProfileSection;
