import { Suspense } from "react";
import { theme as defaultTheme } from "./config/theme";
import Routes from "./routesComponent";
import { ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import { StyledEngineProvider } from "@mui/material/styles";
import AppLoader from "./components/atoms/appLoader/appLoader";
import CustomSnackbarProvider from "./components/atoms/snackbarProvider/customSnackbarProvider";
import "./utils/i18n";
import "./index.css";

/**
 * Main Application Component.
 *
 * The `App` component serves as the entry point of the application. It sets up the theme,
 * handles loading states, and renders the main content or a loader component while
 * asynchronously loading routes.
 *
 * @returns {JSX.Element} The Main Application Component as JSX.
 */
const App = () => {
  const loader = false;
  const appVariables = useSelector((store) => store?.appReducer);
  const newTheme = defaultTheme(appVariables);

  return (
    <StyledEngineProvider injectFirst>
      <Suspense fallback={<AppLoader />}>
        <ThemeProvider theme={newTheme}>
          <CustomSnackbarProvider>
            {loader ? <AppLoader /> : <Routes />}
          </CustomSnackbarProvider>
        </ThemeProvider>
      </Suspense>
    </StyledEngineProvider>
  );
};

export default App;
