import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import SifioLogo from "../../../svgs/sifioLogo";
import { Drawer, DrawerHeader } from "./styledSideBar";
import {
  setSideBarMenu,
  setSideBarState,
} from "../../../store/appReducer/appReducer";
import { sideBarStateSelector } from "../../../store/appReducer/app.selectors";
import { sidebarMenus } from "./menus";
import { sidebarStyles } from "./useStyles";
import ProfileSection from "./profileSection/profileSection";
import { removeDateFromString } from "../../../utils/stringFunctions";
import { callGetAppVersion } from "../../../pages/signIn/signInServices";

const MenuListItem = ({ menu, location, setSideMenuItems, handleClick }) => {
  const dateRemovedString = removeDateFromString(location.pathname);
  const isSelected = menu.itemPaths.includes(dateRemovedString);
  const listItemStyles = isSelected
    ? sidebarStyles.selectedMenuItem
    : sidebarStyles.menuItem;

  return (
    <ListItem
      id={menu.id}
      onMouseEnter={() => setSideMenuItems(menu)}
      onClick={() => handleClick(menu)}
      sx={listItemStyles}
    >
      <ListItemIcon sx={sidebarStyles.listItemIcon}>{menu.icon}</ListItemIcon>
    </ListItem>
  );
};

export default function SideBar() {
  const open = useSelector(sideBarStateSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const setSideMenuItems = (sideVal) => {
    dispatch(setSideBarMenu(sideVal));
  };

  const handleClick = (menuVal) => {
    navigate(menuVal?.path);
  };

  React.useEffect(() => {
    dispatch(callGetAppVersion());
  }, [open]);

  const renderMenuItems = () => {
    return sidebarMenus.map((menu) => (
      <React.Fragment key={menu.id}>
        <MenuListItem
          menu={menu}
          location={location}
          setSideMenuItems={setSideMenuItems}
          handleClick={handleClick}
        />
      </React.Fragment>
    ));
  };

  const handleMouse = (val) => {
    dispatch(setSideBarState(val));
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{ ...sidebarStyles.drawerMainContainer }}
    >
      <DrawerHeader sx={sidebarStyles.drawerHeader}>
        <SifioLogo />
      </DrawerHeader>
      <Grid
        container
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        sx={sidebarStyles.fullHeight}
      >
        <Grid item>
          <List onMouseEnter={() => handleMouse(true)}>
            {renderMenuItems()}
          </List>
        </Grid>
        <Grid item>
          <ProfileSection />
        </Grid>
      </Grid>
    </Drawer>
  );
}
