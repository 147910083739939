import { combineReducers } from "@reduxjs/toolkit";
import { appReducer } from "./appReducer/appReducer";
import { notifications } from "../components/molecules/notifier/notifierReducer";
import { resendTimer } from "../pages/forgotPassword/reducer";
import { selectedDateRange } from "../components/molecules/dateFilter/dateReducer";
import { utilizationReducer } from "../pages/dashboard/dashboardSubroutes/overallUtilization/utilizationReducer/utilizationReducer";
import { overAllUtalizationReducer } from "../pages/dashboard/dashboardSubroutes/overView/overallUtilization/overAllUtalizationReducer";
import { acuityReducer } from "../pages/dashboard/dashboardSubroutes/overView/acuity/acuityReducer";
import { caseVolumeReducer } from "../pages/dashboard/dashboardSubroutes/overView/caseVolume/caseVolumeReducer";
import { overAllUrgencyReducer } from "../pages/dashboard/dashboardSubroutes/overView/overallUrgency/overAllUrgencyReducer";
import { optimizationRecommendationReducer } from "../pages/optimizationRecommendations/optimizationSubroutes/optimizationRecommendationReducer";

const reducer = combineReducers({
  appReducer,
  notifications,
  resendTimer,
  selectedDateRange,
  utilizationReducer,
  overAllUtalizationReducer,
  acuityReducer,
  overAllUrgencyReducer,
  caseVolumeReducer,
  optimizationRecommendationReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

export default rootReducer;
