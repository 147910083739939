import { createSlice } from "@reduxjs/toolkit";
import { LightThemeColors } from "../../constants/colors/lightThemeColors";

const initialState = {
  language: "en",
  theme: "light",
  colors: LightThemeColors,
  sideBarOpen: false,
  openMenus: [],
  sideBarMenu: [],
  headerCheckBoxUtils: "Utilization",
  headerCheckBoxType: "Programs",
  user: {},
  version: "",
  refreshToggler: false,
  refreshLoading: false,
};

export const appSlice = createSlice({
  name: "appVariables",
  initialState,
  reducers: {
    setCurrentTheme(state, action) {
      state.theme = action.payload;
    },
    setSideBarState(state, action) {
      state.sideBarOpen = action.payload;
    },
    setOpenMenus(state, action) {
      state.openMenus = action.payload;
    },
    setSideBarMenu(state, action) {
      state.sideBarMenu = action.payload;
    },
    setVersion(state, action) {
      state.version = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setheaderCheckBoxUtils(state, action) {
      state.headerCheckBoxUtils = action?.payload;
    },
    setheaderCheckBoxType(state, action) {
      state.headerCheckBoxType = action?.payload;
    },
    setRefreshToggler(state) {
      state.refreshToggler = !state?.refreshToggler;
    },

    setRefreshLoadingTrue(state) {
      state.refreshLoading = true;
    },
    setRefreshLoadingFalse(state) {
      state.refreshLoading = false;
    },
  },
});

export const {
  setCurrentTheme,
  setSideBarState,
  setOpenMenus,
  setSideBarMenu,
  setUser,
  setVersion,
  setheaderCheckBoxUtils,
  setheaderCheckBoxType,
  setRefreshToggler,
  setRefreshLoadingTrue,
  setRefreshLoadingFalse,
} = appSlice.actions;

export const appReducer = appSlice.reducer;
