const LogoutIcon = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.332 2.66667L9.39203 3.60667L11.112 5.33333H4.33203V6.66667H11.112L9.39203 8.38667L10.332 9.33333L13.6654 6L10.332 2.66667ZM1.66536 1.33333H6.9987V0H1.66536C0.932031 0 0.332031 0.6 0.332031 1.33333V10.6667C0.332031 11.4 0.932031 12 1.66536 12H6.9987V10.6667H1.66536V1.33333Z"
        fill="#2C6150"
      />
    </svg>
  );
};

export default LogoutIcon;
