import { createSlice } from "@reduxjs/toolkit";
import { revertAll } from "../../../utils/storeUtils";

const initialState = {
  optimizationRecommendation: "",
  weekDataForOptimization: "",
  optimizationByDayDetails: "",
  thirdWeekPlanned: "",
  dailyWarnings: {},
  dailyRecommendations: {},
};

export const optimizationRecommendationSlice = createSlice({
  name: "optimizationRecommendation",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setOptimizationRecommendation(state, action) {
      state.optimizationRecommendation = action.payload;
    },
    setWeekDataOptimizationRecommendation(state, action) {
      state.weekDataForOptimization = action.payload;
    },
    setOptimizationByDayDetails(state, action) {
      state.optimizationByDayDetails = action.payload;
    },
    setThirdWeekPlanned(state, action) {
      state.thirdWeekPlanned = action.payload;
    },
    setDailyWarnings(state, action) {
      state.dailyWarnings = action?.payload;
    },
    setDailyRecommendations(state, action) {
      state.dailyRecommendations = action?.payload;
    },
  },
});

export const {
  setOptimizationRecommendation,
  setWeekDataOptimizationRecommendation,
  setOptimizationByDayDetails,
  setThirdWeekPlanned,
  setDailyWarnings,
  setDailyRecommendations,
} = optimizationRecommendationSlice.actions;

export const optimizationRecommendationReducer =
  optimizationRecommendationSlice.reducer;
