import { createSlice } from "@reduxjs/toolkit";
import { revertAll } from "../../../../../utils/storeUtils";

const initialState = {
  overAllUtalization: "",
};

export const overAllUtalizationSlice = createSlice({
  name: "overAllUtalization",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setOverAllUtalization(state, action) {
      state.overAllUtalization = action.payload;
    },
  },
});

export const { setOverAllUtalization } = overAllUtalizationSlice.actions;

export const overAllUtalizationReducer = overAllUtalizationSlice.reducer;
