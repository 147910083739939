import OverviewIcon from "../../../svgs/overviewIcon";
import PrimeTimeIcon from "../../../svgs/primeTimeIcon";
import UrgencyIcon from "../../../svgs/urgencyIcon";
import AcuityIcon from "../../../svgs/acuityIcon";
import HomeIcon from "../../../svgs/homeIcon";
import OptimizationIcon from "../../../svgs/optimizationIcon";
import RecommendationsIcon from "../../../svgs/recommendationsIcon";

export const sidebarMenus = [
  {
    id: "optimization",
    label: "Prediction & Optimization",
    icon: <HomeIcon />,
    path: "/optimization",
    itemPaths: [
      "/optimization",
      "/optimization/",
      "/optimization/by-week",
      "/optimization/outcomes",
      "/optimization/daily-insights",
    ],
    active: true,
    items: [
      {
        id: "optimization",
        label: "Overview",
        icon: <PrimeTimeIcon />,
        path: "/optimization",
        includedPaths: [
          "/optimization",
          "/optimization/by-week",
          "/optimization/outcomes",
        ],
        active: true,
      },
      {
        id: "recommendations",
        label: "Recommendations",
        icon: <RecommendationsIcon />,
        path: "/optimization/daily-insights",
        includedPaths: ["/optimization/daily-insights"],
        active: true,
      },
    ],
  },
  {
    id: "dashboard",
    label: "Performance Management",
    icon: <OptimizationIcon />,
    path: "/dashboard",
    itemPaths: [
      "/dashboard",
      "/dashboard/overall-utilization",
      "/dashboard/urgency",
      "/dashboard/acuity",
      "/dashboard/case-volume",
    ],
    active: true,
    items: [
      {
        id: "overview",
        label: "Overview",
        path: "/dashboard",
        includedPaths: ["/dashboard"],
        icon: <OverviewIcon />,

        active: true,
      },
      {
        id: "primeTimeUtilization",
        label: "Prime Time Utilization",
        icon: <PrimeTimeIcon />,
        path: "/dashboard/overall-utilization",
        includedPaths: ["/dashboard/overall-utilization"],

        active: true,
      },
      {
        id: "urgency",
        label: "Urgency",
        icon: <UrgencyIcon />,
        path: "/dashboard/urgency",
        includedPaths: ["/dashboard/urgency"],

        active: true,
      },
      {
        id: "acuity",
        label: "Acuity",
        icon: <AcuityIcon />,
        path: "/dashboard/acuity",
        includedPaths: ["/dashboard/acuity"],

        active: true,
      },
    ],
  },
];
