import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  resendTimer: 60,
  forgotPassEmail: "",
};

export const resendEmailTimerSlice = createSlice({
  name: "resendEmailTimer",
  initialState,
  reducers: {
    setResendEmailTimer(state) {
      state.resendTimer = state.resendTimer - 1;
    },
    setResetTimer(state) {
      state.resendTimer = 60;
    },
    setForgotPassEmail(state, action) {
      state.forgotPassEmail = action.payload;
    },
  },
});

export const { setResendEmailTimer, setResetTimer, setForgotPassEmail } =
  resendEmailTimerSlice.actions;
export const resendTimer = resendEmailTimerSlice.reducer;
