const PrimeTimeIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2 12.9997C16.477 12.9997 16.6155 12.9997 16.7278 13.0612C16.8204 13.1119 16.9065 13.2072 16.9478 13.3044C16.9978 13.4222 16.9852 13.5476 16.96 13.7984C16.8296 15.0984 16.3822 16.3511 15.6518 17.4442C14.7727 18.7598 13.5233 19.7852 12.0615 20.3907C10.5997 20.9962 8.99113 21.1546 7.43928 20.846C5.88743 20.5373 4.46197 19.7754 3.34315 18.6565C2.22433 17.5377 1.4624 16.1123 1.15372 14.5604C0.84504 13.0086 1.00347 11.4 1.60897 9.93821C2.21447 8.47641 3.23985 7.22698 4.55544 6.34793C5.64856 5.61752 6.90125 5.17009 8.20131 5.03965C8.45207 5.01449 8.57745 5.00191 8.69528 5.0519C8.79249 5.09314 8.88776 5.17933 8.9385 5.27193C9 5.38419 9 5.52268 9 5.79968V12.1997C9 12.4797 9 12.6197 9.0545 12.7267C9.10244 12.8208 9.17893 12.8972 9.27301 12.9452C9.37996 12.9997 9.51998 12.9997 9.8 12.9997H16.2Z"
        stroke="#D1E2DA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 1.79968C13 1.52268 13 1.38418 13.0615 1.27192C13.1122 1.17932 13.2075 1.09313 13.3047 1.05189C13.4225 1.0019 13.5479 1.01447 13.7987 1.03963C15.6271 1.22302 17.346 2.03198 18.6569 3.34283C19.9677 4.65367 20.7767 6.37258 20.9601 8.20098C20.9852 8.45175 20.9978 8.57714 20.9478 8.69497C20.9066 8.79217 20.8204 8.88744 20.7278 8.93818C20.6155 8.99968 20.477 8.99968 20.2 8.99968L13.8 8.99968C13.52 8.99968 13.38 8.99968 13.273 8.94519C13.1789 8.89725 13.1024 8.82076 13.0545 8.72668C13 8.61972 13 8.47971 13 8.19968V1.79968Z"
        stroke="#D1E2DA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PrimeTimeIcon;
